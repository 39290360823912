@use '../../constants.scss' as *;
.register-button {
  background-color: $secondary-cta-bkg-color;
  color: $secondary-cta-color;
  text-align: center;
  border: 2px solid $secondary-cta-border-color;
  border-radius: 25px;
  padding: 12px 20px;
  font-family: $rubik-font;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
}
