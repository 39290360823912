@use '../../constants.scss' as *;
.game-lobby {
  width: 100%;
  margin: auto;
  max-width: $breakpoint-desktop;
  box-sizing: border-box;
  padding-bottom: 100px;
  &__games {
    gap: 12px;
    row-gap: 24px;
    justify-content: center;
    display: grid;
    grid-template-columns: $game-tile-width;
    margin-top: 32px;
    img {
      max-height: 150px;
    }
    @media screen and (min-width: 330px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (min-width: $breakpoint-game-tile-resize) {
      grid-template-columns: repeat(auto-fill, $game-tile-width);
    }
  }
}
