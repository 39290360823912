@use '../../constants.scss' as *;
.date-range-selector {
  width: 100%;
  max-width: 300px;
  font-family: $font-body;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  &__input {
    background-color: #ffffff;
    width: 100%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $input-border-color;
    gap: 2px;
    color: #231f20;
  }
  &__calendar-icon {
    padding: 8px;
    margin: 0 8px;
    display: flex;
    border-radius: 2px;
    &:hover {
      background-color: lightgray;
    }
  }
  &__close-button {
    border-radius: 100%;
    padding: 4px;
    display: flex;
    &:hover {
      background-color: lightgray;
    }
  }
  &__label {
    color: #2b3037;
    font-size: 14px;
  }
  &__text {
    font-family: $rubik-font;
    font-size: 16px;
    padding: 12px 16px;
    text-align: left;
    width: 100%;
    &--placeholder {
      color: $input-placeholder-color;
    }
  }
  &__modal {
    &__content {
      width: auto;
      max-width: 300px;
    }
  }
}

.date-range-calendar {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 20px 24px;
  box-sizing: border-box;
  &__header-text {
    font-size: 16px;
    font-style: normal;
    font-family: $font-body;
  }
  &__month-text {
    font-size: 16px;
    font-family: $font-input;
    margin-right: 2px;
  }
  &__month-and-year {
    display: flex;
    align-items: center;
  }
  &__month-button {
    padding: 8px;
  }
  &__month-buttons {
    display: flex;
    column-gap: 8px;
  }
  &__months-container {
    overflow: hidden;
    position: relative;
    min-height: 224px;
  }
  &__nav-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
