@use '../../constants.scss' as *;
.fast-play-game-details-page {
  font-family: $rubik-font;
  &__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 8px 0;
  }
  &__disclaimer {
    font-family: $rubik-font;
    font-size: 16px;
    align-self: flex-start;
  }
  &__horizontal {
    display: flex;
    align-items: flex-start;
    gap: 8px;
  }
  &__game-name {
    font-size: 30px;
    font-weight: 600;
    font-family: $obviously-font;
    margin: 0;
  }
  &__image-container {
    position: relative;
    display: flex;
    justify-content: center;
  }
  &__info {
    width: 100%;
  }
  &__inline-label {
    font-size: 14px;
    font-weight: 500;
    color: #676b72;
  }
  &__jackpot-value {
    font-weight: 600;
    color: $primary-green;
    font-size: 24px;
  }
  &__odds-header {
    font-family: $obviously-font;
    font-size: 24px;
    margin: 0;
  }
  &__price {
    margin-top: 12px;
  }
  &__results {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  &__value-and-label {
    display: flex;
    gap: 6px;
    align-items: center;
  }
}
