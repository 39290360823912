@use '../../constants.scss' as *;
@use 'sass:color';

.instagram-icon {
  fill: #fff;
  fill-rule: evenodd;
  &:hover {
    fill: color.adjust(#fff, $lightness: -15%);
  }
}
