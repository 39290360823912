@use '../../constants.scss' as *;
.home-page {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  align-items: center;
  max-width: unset;
  &__account-widget-container {
    width: calc(100% - 32px);
    margin: 0 auto;
  }
  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
  }
  &__carousels {
    width: 100%;
    max-width: $max-content-width;
    margin: 0 auto;
  }
  &__cms-content {
    width: 100%;
    max-width: $max-content-width;
    display: flex;
    flex-direction: column;
    gap: 12px;
    box-sizing: border-box;
    padding: 0 12px;
    @media screen and (min-width: $max-content-width) {
      padding: 0;
    }
    & > * {
      width: 100%;
      margin: 0 auto;
    }
  }
  &__image-banner {
    width: 100vw;
    left: calc(-50vw + 50%);
    position: relative;
  }
}
