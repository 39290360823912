@use '../../constants.scss' as *;
@use 'sass:color';

.winning-numbers-card,
.rapid-game-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  min-width: 269px;
  min-height: 310px;
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0px 5px 10px #00000026;
  font-family: $rubik-font;
  background-color: #ffffff;

  &--is-loading {
    align-items: center;
    justify-content: center;
  }
  &--has-error {
    align-items: center;
  }
  &__core-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
  }
  &__error {
    color: color.adjust(black, $lightness: 40%);
    text-align: center;
    width: 80%;
    font-size: 14px;
    font-style: italic;
  }
  &__final-link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    color: #2b3037;
    font-weight: 700;
    padding-top: 10px;
    border-top: 1px solid #f4f4f6;
    margin-top: 10px;
  }
  &__icon-and-date {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    min-height: 130px;
    width: 100%;
    gap: 16px;
  }
  &__loading-container {
    min-height: 72px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__winning-numbers-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &__win-location {
    text-align: center;
    font-family: $obviously-font;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    &--no-multiplier {
      margin-top: 24px;
    }
  }
  &__win-location-value {
    font-weight: bold;
  }
}
