@use '../../constants.scss' as *;
.input-search {
  position: relative;
  width: 100%;
  &__icon {
    position: absolute;
    right: 16px;
    bottom: 6px;
    pointer-events: none;
    cursor: pointer;
  }
  &__search-field {
    font-size: 16px;
  }
}
