@use '../../constants.scss' as *;
.draw-games-icon {
  &__cls-1 {
    fill: none;
    stroke: #000;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 8.5px;
  }

  &__cls-2 {
    fill: #fff;
  }
}
