@use '../../constants.scss' as *;
.date-selector {
  width: 100%;
  max-width: 300px;
  font-family: $font-body;
  font-size: 12px;
  font-weight: 600;
  &__input {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    color: #231F20;
    font-family: $obviously-font;
    font-weight: 600;
    padding-bottom: 6px;
    svg {
      padding-top: 4px;
    }
  }
  &__modal {
    &__content {
      width: auto;
      max-width: 300px;
    }
  }
}

.calendar {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 20px 24px;
  box-sizing: border-box;
  &__header-text {
    font-size: 16px;
    font-style: normal;
    font-family: $font-body;
  }
  &__month-text {
    font-size: 16px;
    font-family: $font-input;
    margin-right: 2px;
  }
  &__month-and-year {
    display: flex;
    align-items: center;
  }
  &__month-button {
    padding: 8px;
  }
  &__month-buttons {
    display: flex;
    column-gap: 8px;
  }
  &__months-container {
    overflow: hidden;
    position: relative;
    min-height: 224px;
  }
  &__nav-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
