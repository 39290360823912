@use '../../constants.scss' as *;
@use 'sass:color';

.input-select {
  $vertical-padding: 8px;
  $side-padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  &__chevron {
    position: absolute;
    right: $side-padding - 4;
    top: $vertical-padding;
    pointer-events: none;
    cursor: pointer;
  }
  &__input {
    border-radius: 4px;
    border: 1px solid $input-border-color;
    padding: $vertical-padding $side-padding;
    padding-right: $side-padding * 2 + 8;
    color: $input-color;
    cursor: pointer;
    appearance: none;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 16px;
    &--no-border {
      border: none;
    }
    &--error {
      border: 1px solid red;
    }
  }
  select:required:invalid {
    color: $input-placeholder-color;
    font-style: italic;
  }
  &__input-wrapper {
    position: relative;
    width: 100%;
  }
  option[disabled] {
    display: none;
  }
}
