@use '../../constants.scss' as *;
@use 'sass:color';

.input-text {
  display: flex;
  flex-direction: column;
  gap: 4px;
  &__field {
    padding: 12px 16px;
    border: 1px solid $input-border-color;
    border-radius: 4px;
    font-family: $font-input;
    font-size: 16px;
    &::placeholder {
      color: color.adjust($input-color, $lightness: 50%);
    }
    &--error {
      border: 1px solid red;
    }
    &--no-border {
      border: none;
    }
  }
}
