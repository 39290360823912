@use '../../constants.scss' as *;
.rapid-game-details-page {
  &__page-info {
    padding: 0px 16px;
    font-family: $rubik-font;
    font-size: 14px;
    color: #2b3037;
    width: 100%;
  }
  &__bottom {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }
  &__image-container {
    position: relative;
  }
  &__image {
    display: flex;
    justify-content: center;
    img {
      border-radius: 16px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 16px 0px;
  }
  &__horizontal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: $breakpoint-average-mobile;
    gap: 16px;
  }
  &__vertical {
    display: flex;
    flex-direction: column;
    gap: 4px;
    text-align: right;
  }
  &__text {
    font-size: 14px;
    font-weight: 500;
  }
  &__text--large {
    font-size: 18px;
    font-weight: 600;
  }
  &__state {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    width: 100%;
  }
}

.keno-app-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  gap: 16px;
  padding: 16px;
  border-radius: 2px;
  background-color: #f3f5f6;
  color: #2b3037;
  font-family: 'Open Sans';

  &__horizontal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }
  &__vertical {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  &__image {
    max-width: 84px;
    max-height: 84px;
  }
  &__title {
    font-size: 16px;
    font-weight: 600;
  }
  &__text {
    font-size: 12px;
    font-weight: 500;
  }
  &__badge {
    height: 50px;
    img {
      object-fit: contain;
    }
  }
}
