@use 'sass:color';
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700,900|Roboto+Slab:400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://use.typekit.net/pqb4kww.css');

$roboto-font: 'Roboto', sans-serif;
$roboto-slab-font: 'Roboto Slab', serif;
$rubik-font: 'Rubik', sans-serif;
$obviously-font: 'obviously', sans-serif;
$obviously-narrow-font: 'obviously-narrow', sans-serif;
$obviously-wide-font: 'obviously-wide', sans-serif;

// colors
$primary-green: #186259;
$primary-navy: rgb(19, 52, 80);
$accent-navy: #1b4b51;
$primary-red: #dc3f1c;
$accessible-red: #de3517;

$ilottery-blue: rgb(21, 56, 107);
$ilottery-gradient: linear-gradient(
  to bottom,
  rgba(10, 34, 57, 1) 0%,
  rgba(44, 87, 120, 1) 100%
);

$in-store-yellow: #fdcd0d;

$header-color: black;
$cta-border-color: black;
$input-color: black;
$input-border-color: #b2b5b9;
$input-placeholder-color: #757575;
$nav-link-color: #2b3037;
$error-color: red;
$primary-cta-color: white;
$primary-cta-bkg-color: $primary-green;
$secondary-cta-color: $primary-green;
$secondary-cta-bkg-color: white;
$secondary-cta-border-color: $primary-green;
$inline-link-color: rgba(237, 28, 36, 1);

$regular-filter-color: black;
$regular-filter-bkg-color: white;
$regular-filter-color-selected: white;
$regular-filter-bkg-color-selected: black;

$transparent-filter-color: white;
$transparent-filter-bkg-color: transparent;
$transparent-filter-color-selected: $ilottery-blue;
$transparent-filter-bkg-color-selected: white;

// fonts
$font-body: $rubik-font;
$font-input-label: $rubik-font;
$font-input: $rubik-font;
$font-headers: $obviously-font;
$font-subheader: $rubik-font;
$font-cta: $rubik-font;

// breakpoints
$breakpoint-small-mobile: 320px;
$breakpoint-average-mobile: 360px;
$breakpoint-game-tile-resize: 510px;
$breakpoint-tablet: 600px;
$breakpoint-desktop: 1008px;
$max-content-width: 1200px;

// layout
$game-tile-width: 230px;
$game-tile-height: 100px;

// z-index
$z-index-menu-bar: 100;
$z-index-skip-to-main: 400;
$z-index-game-window: 1000;
$z-index-sidebar: 2000;
$z-index-modal: 3000;

@mixin cta {
  border-radius: 25px;
  padding: 12px;
  font-family: $rubik-font;
  font-weight: bold;
  text-align: center;
}

@mixin primary-cta {
  @include cta;
  background-color: $primary-cta-bkg-color;
  color: $primary-cta-color;
}

@mixin secondary-cta {
  @include cta;
  background-color: $secondary-cta-bkg-color;
  color: $secondary-cta-color;
  border: 1px solid $secondary-cta-border-color;
}

@mixin ilottery-background {
  background-color: $ilottery-blue;
  background-image: $ilottery-gradient;
}
