@use '../../constants.scss' as *;
.game-rules {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  &__accordion {
    width: 100%;
    &__header {
      border-top: 1px solid var(--grey-200, #dddfe1);
      box-sizing: border-box;
      padding: 16px;
      font-size: 18px;
      font-family: $font-body;
    }
  }
  &__content {
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .cta {
    align-self: center;
  }
}
