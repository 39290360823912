@use '../../constants.scss' as *;
.past-results {
  @media screen and (min-width: $breakpoint-desktop) {
    padding: 0 16px;
  }
  &__state-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    width: 100%;
  }
  &__load-more-button {
    border-radius: 5px;
    background-color: $secondary-cta-bkg-color;
    color: $secondary-cta-color;
    padding: 12px 24px;
    font-family: $rubik-font;
    font-weight: bold;
    border: 2px solid $secondary-cta-border-color;
  }
  &__load-more-container {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }
  &__download-button {
    display: none;
    visibility: hidden;
    @media screen and (min-width: $breakpoint-desktop) {
      display: inline-block;
      visibility: visible;
      font-size: 17px;
      font-weight: 600;
      margin-top: 24px;
    }
  }
}
