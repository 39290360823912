@use '../../constants.scss' as *;
.winning-numbers {
  $ball-size: 33px;
  $number-gap: 9px;
  $powerball-color: #d64127;
  $megaball-color: #0449ab;
  $luckyball-color: #007023;
  $megabucks-color: #0078c6;
  width: 100%;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-family: $rubik-font;
  &--no-multiple-draws {
    gap: 16px;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    &--left {
      justify-content: flex-start;
    }
    &--center {
      justify-content: center;
    }
    &--right {
      justify-content: flex-end;
    }
  }
  &__numbers {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $number-gap;
    min-height: 32px;
  }
  &__base-numbers {
    display: flex;
    flex-wrap: wrap;
    max-width: min(100%, calc(10 * $ball-size) + calc(9 * $number-gap));
    gap: $number-gap;
  }
  &__multiplier {
    color: #2b3037;
    text-align: center;
    font-size: 17px;
    font-weight: 600;
  }
  &__multiplier-text {
    color: #2b3037;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
  }
  &__pending {
    font-size: 16px;
    font-style: italic;
    text-align: center;
  }
  &__next-draw {
    color: #666666;
    font-size: 12px;
    font-weight: 500;
    font-style: italic;
    text-align: left;
  }
  &__label {
    font-size: 12px;
    font-weight: 500;
    text-align: left;
  }
  &__single-number {
    flex-shrink: 0;
    background-color: white;
    border-radius: 100%;
    display: flex;
    width: $ball-size;
    height: $ball-size;
    padding-bottom: 5px;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    color: var(--grey-900, #2b3037);
    text-align: center;
    font-family: $obviously-narrow-font;
    font-size: 18px;
    font-weight: 800;
    box-shadow: 10px 8px 13px #1e252942;
    border: 1px solid #e7e7e4;
    &--powerball {
      background-color: $powerball-color;
      color: white;
    }
    &--megaball {
      background-color: $megaball-color;
      color: white;
    }
    &--luckyball {
      background-color: $luckyball-color;
      color: white;
    }
    &--megabucks {
      background-color: $megabucks-color;
      color: white;
    }
    &--default-special {
      background-color: black;
      color: white;
    }
    &--with-label {
      width: 32px;
      height: 32px;
      font-size: 20px;
      flex-wrap: wrap;
    }
    &--underline {
      text-decoration: underline;
      text-decoration-thickness: 2.2px;
    }
  }
}
