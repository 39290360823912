@use '../../constants.scss' as *;
.winners {
  padding-bottom: 100px;
  &__state-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    width: 100%;
  }
}