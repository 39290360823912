@use '../../constants.scss' as *;
.pending-animation {
  display: flex;
  flex-direction: column;
  align-items: center;
  &--left {
    align-items: flex-start;
  }
  &--right {
    align-items: flex-end;
  }
  &__animation {
    max-width: 100px;
  }
  &__text {
    font-family: $obviously-narrow-font;
    font-size: 16px;
    color: $accessible-red;
    font-style: italic;
    font-weight: 600;
    position: relative;
    &--with-asterisk {
      &::after {
        content: '*';
        font-size: 10px;
        position: absolute;
        top: 0;
        right: -8px;
      }
    }
  }
}
