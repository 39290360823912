@use '../../constants.scss' as *;
@use 'sass:color';

.error-message {
  font-style: italic;
  color: color.adjust(black, $lightness: 30%);
  font-size: 14px;
  font-family: $font-body;
  text-align: center;
  display: block;
}
