@use '../../constants.scss' as *;
@use 'sass:color';

.ilottery-game-window {
  background-color: #c8cbd4;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  box-sizing: border-box;
  z-index: $z-index-game-window;
  &--is-visible {
    display: flex;
    flex-direction: column;
    visibility: visible;
  }
  .ng-game-container {
    width: 100%;
    height: 100%;
    margin: auto;
  }
  &__close-button {
    border-radius: 100%;
    background-color: white;
    padding: 4px;
    display: flex;
    margin-left: 7%;
    &:hover {
      background-color: color.adjust(white, $lightness: -20%);
    }
  }
  &__cta {
    @include primary-cta;
    justify-self: center;
  }
  &__header {
    display: none;
    visibility: hidden;
    padding: 16px;
    background-color: white;
    z-index: $z-index-sidebar;
    width: 100%;
    box-sizing: border-box;
    @media screen and (min-width: $breakpoint-desktop) {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      align-items: center;
      visibility: visible;
    }
    &--is-mobile-or-tablet {
      display: none;
      visibility: hidden;
    }
  }
  &__loading-container {
    position: absolute;
    top: 50%;
    left: 50%;
  }
  &__logo {
    width: 124px;
    max-width: 16%;
  }
}
