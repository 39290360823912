@use '../../constants.scss' as *;
.past-results-form {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 16px;
  box-sizing: border-box;
  align-items: flex-start;
  margin: auto;
  @media screen and (min-width: $breakpoint-desktop) {
    padding: 0;
    max-width: unset;
    flex-direction: row;
    align-items: flex-end;
  }
  &--no-game {
    @media screen and (min-width: $breakpoint-desktop) {
      justify-content: space-between;
    }
  }
  &--rapid {
    flex-direction: column;
    align-items: flex-start;
  }
  &__inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    box-sizing: border-box;
    @media screen and (min-width: $breakpoint-desktop) {
      flex-direction: row;
      align-items: baseline;
      flex-wrap: wrap;
      gap: 16px;
    }
    & > *:not(:last-child) {
      @media screen and (min-width: $breakpoint-desktop) {
        width: 30%;
        max-width: calc((100% - 32px) / 3);
      }
    }
  }
  button.past-results-form__submit-button {
    padding: 12px 24px;
    border-radius: 25px;
    font-family: $rubik-font;
    font-weight: bold;
    background-color: $primary-cta-bkg-color;
    color: white;
    @media screen and (min-width: $breakpoint-desktop) {
      margin-bottom: 2px;
    }
  }
  &__your-numbers {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  &__your-numbers-inputs {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    max-width: 320px;
    @media screen and (min-width: $breakpoint-desktop) {
      max-width: unset;
      flex-wrap: nowrap;
    }
    & > * {
      width: 46px;
    }
  }
  input.past-results-form__number,
  input.past-results-form__special-ball {
    padding-right: 0;
    padding-left: 0;
    text-align: center;
    font-size: 16px;
    appearance: textfield;
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  input.past-results-form__special-ball {
    border: 2px solid var(--special-ball-color, #676b72);
  }
  &__drawings-select,
  &__game-select {
    @media screen and (min-width: $breakpoint-desktop) {
      width: 100%;
      max-width: 315px;
    }
    select {
      padding: 12px;
    }
    &__chevron {
      top: 12px;
    }
  }
}
