@use '../../constants.scss' as *;
.game-price {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  min-width: 45px;
  border-radius: 50px;
  background-color: #276191;
  color: white;
  padding: 6px;
  font-size: 16px;
  border-radius: 0 15px 0 15px;
}
