@use '../../constants.scss' as *;
.image-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color);
  flex: 1;
  &__mobile-image {
    @media screen and (min-width: $breakpoint-desktop) {
      display: none;
      visibility: hidden;
    }
  }
  &__desktop-image {
    display: none;
    visibility: hidden;
    @media screen and (min-width: $breakpoint-desktop) {
      display: flex;
      visibility: visible;
    }
  }
}
