@use '../../constants.scss' as *;
.winning-numbers-page {
  &__content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    width: 100%;
    max-width: 100vw;
    box-sizing: border-box;
    padding: 0 16px;
    justify-items: center;
    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (min-width: 1048px) {
      grid-template-columns: repeat(3, 1fr);
    }
    & > * {
      @media screen and (min-width: 768px) {
        &:nth-child(even) {
          justify-self: start;
        }
        &:nth-child(odd) {
          justify-self: end;
        }
      }
      @media screen and (min-width: 1048px) {
        // first column
        &:nth-child(3n + 1) {
          justify-self: end;
        }
        // second column
        &:nth-child(3n + 2) {
          justify-self: unset;
        }
        // third column
        &:nth-child(3n) {
          justify-self: start;
        }
      }
    }
  }
}
