@use '../../constants.scss' as *;
.dot-notification {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: #d84127;
  color: white;
  min-width: 20px;
  min-height: 20px;
  font-family: $rubik-font;
  font-size: 10px;
  font-weight: bold;
  line-height: 1;
  &--no-count {
    min-width: 12px;
    min-height: 12px;
  }
}
