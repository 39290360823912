@use '../../constants.scss' as *;
.game-details-ctas {
  display: flex;
  gap: 16px;
  width: 100%;
  &__primary-button {
    @include primary-cta;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
  }
  &__secondary-button {
    @include secondary-cta;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
  }
}
