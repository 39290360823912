@use '../../constants.scss' as *;
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #186259;
    color: #fff;
    border: none;
    padding: 8px 16px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
    transition: background-color 0.3s;
    max-width: 70px;

    &:hover {
      background-color: #186259;
    }

    &:disabled {
      background-color: #cccccc;
      cursor: not-allowed;
    }

    &--previous,
    &--next {
      font-weight: bold;
      border-radius: 25px;
    }
  }

  &__page-number {
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #000000;
    padding: 8px 12px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 25px;
    font-size: 14px;
    transition: background-color 0.3s;

    &:hover {
      background-color: #f0f0f0;
    }

    &--active {
      background-color: #000000;
      color: #ffffff;
    }
  }
}