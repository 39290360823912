@use '../../constants.scss' as *;
.e-instants-icon {
  &__cls-1 {
    fill: none;
    stroke: #000;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 8.5px;
  }

  &__cls-2 {
    font-family: Obviously-Medi, Obviously;
    font-size: 46.87px;
  }

  &__cls-3 {
    fill: #fff;
  }

  &__cls-4 {
    letter-spacing: 0em;
  }

  &__cls-5 {
    letter-spacing: -0.07em;
  }

  &__cls-6 {
    letter-spacing: -0.01em;
  }
}