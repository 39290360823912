@use '../../constants.scss' as *;
.spotlight-carousel {
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  &--is-loading {
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__items {
    display: flex;
    width: 100%;
    position: relative;
    transition: all 0.3s ease-in-out;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  &__item {
    font-family: $font-body;
    min-width: 100%;
    &--current {
      position: relative;
    }
  }
  &__dots {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    column-gap: 20px;
  }
  button.carousel__dot {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #dddfe1;
    &--is-active {
      background-color: #2b3037;
    }
  }
}
