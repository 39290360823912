@use '../../constants.scss' as *;
.game-carousel {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  position: relative;
  &__header {
    margin: 0;
    font-family: $font-headers;
    color: $header-color;
    font-size: 18px;
    &--is-ilottery {
      color: white;
    }
  }
  &__games {
    padding: 2px;
    padding-bottom: 16px;
    position: relative;
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    column-gap: 8px;
    & > * {
      flex: 0 0 auto;
      max-width: 280px;
      @media screen and (min-width: $breakpoint-game-tile-resize) {
        max-width: $game-tile-width;
      }
    }
  }
}
