@use '../../constants.scss' as *;
.toggle-button {
  display: flex;
  column-gap: 4px;
  background-color: rgb(237, 242, 247);
  border-radius: 30px;
  width: fit-content;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  &__option {
    padding: 6px 10px;
    border-radius: 30px;
    background-color: transparent;
    position: relative;
    z-index: 1;
    color: gray;
    font-weight: bold;
    &--selected {
      color: $accent-navy;
    }
  }
  &__selected-bkg {
    transition:
      left 0.1s ease-in-out,
      width 0.1s ease-in-out;
    position: absolute;
    background-color: white;
    border-radius: 30px;
    height: 100%;
  }
}
