@use '../../constants.scss' as *;
.retailer-info-window {
  font-family: $font-body;
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: #2b3037;
  &__name {
    font-weight: 600;
    font-size: 16px;
  }
  &__address {
    font-size: 14px;
  }
  &__phone {
    font-size: 14px;
    font-weight: 600;
  }
  &__directions-link {
    color: #2b3037;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
  }
}

.retail-finder {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 200px;
  &__input {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 12px;
    @media screen and (min-width: $breakpoint-desktop) {
      padding: 0;
    }
  }
  &__controls {
    display: flex;
    flex-direction: column;
    gap: 24px;
    box-sizing: border-box;
    width: 100%;
    @media screen and (min-width: $breakpoint-desktop) {
      flex-direction: row;
      justify-content: space-between;
      gap: 16px;
    }
  }
  &__filters {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (min-width: $breakpoint-desktop) {
      width: 50%;
    }
  }
  &__filter-buttons {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  &__results {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    @media screen and (min-width: $breakpoint-desktop) {
      flex-direction: row-reverse;
      gap: 12px;
    }
    & > *:first-child {
      padding: 0 12px;
      @media screen and (min-width: $breakpoint-desktop) {
        padding: 0;
      }
    }
  }
  &__reset-filters {
    &--active {
      font-weight: bold;
    }
  }
}

.retail-finder-list {
  font-family: $font-body;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  @media screen and (min-width: $breakpoint-desktop) {
    width: 55%;
  }
  &__address {
    font-size: 16px;
  }
  &__phone {
    font-size: 14px;
  }
  &__header {
    padding: 16px;
    font-size: 18px;
    font-weight: 600;
    background-color: #276191;
    color: white;
  }
  &__name {
    font-size: 18px;
    font-weight: 600;
  }
  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 756px;
    overflow: auto;
  }
  &__loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    &:nth-of-type(odd) {
      background-color: #e8f1f6;
    }
    &:nth-of-type(even) {
      background-color: #d0e1eb;
    }
  }
  &__item-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  &__no-results {
    margin: 10px auto;
    text-align: center;
    font-style: italic;
    font-size: 16px;
    display: inline-block;
    width: 100%;
  }
}
