@use '../../constants.scss' as *;
.input-multi-select {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  font-size: 16px;
  &__select {
    font-family: $font-input;
    border-radius: 4px;
    &--error {
      border: 1px solid $error-color;
    }
  }
  &__control {
    padding: 3px 0;
  }
}
