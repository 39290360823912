@use '../../constants.scss' as *;
.cms-text {
  font-family: $font-body;
  color: var(--text-color, black);
  &__embedded-image {
    justify-content: center;
    margin: 8px auto;
    img {
      width: auto;
      max-width: 100%;
    }
  }
  &__paragraph {
    color: var(--text-color, black);
    &:not(:first-child) {
      margin-top: 12px;
    }
  }
  &__heading {
    font-family: $font-headers;
    color: var(--text-color, black);
    &:first-child {
      margin-top: 0;
    }
  }
  &__inline-link {
    color: $inline-link-color;
    font-weight: bold;
  }
  &__inline-content {
    display: inline;
    &--bold {
      font-weight: bold;
    }
    &--italic {
      font-style: italic;
    }
    &--underline {
      text-decoration: underline;
    }
  }
}
