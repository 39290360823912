@use '../../constants.scss' as *;
@use 'sass:color';

@mixin filters-container {
  display: inline-flex;
  column-gap: 16px;
  row-gap: 16px;
  & > * {
    flex-shrink: 0;
  }
}

.game-lobby-query {
  padding: 16px 0;
  padding-top: 24px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  &-filters {
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    @include filters-container();
    @media screen and (min-width: $breakpoint-desktop) {
      align-items: flex-start;
    }
    &__filter-by-text {
      font-family: $rubik-font;
      font-weight: bold;
      color: black;
      font-size: 14px;
      flex-shrink: 0;
      &--is-ilottery {
        color: white;
      }
    }
    &__more-filters-button {
      visibility: visible;
      display: flex;
      padding: 12px 16px;
      align-items: center;
      border-radius: 25px;
      font-family: $rubik-font;
      font-weight: bold;
      color: $regular-filter-color;
      background-color: $regular-filter-bkg-color;
      &--is-transparent {
        border: 1px solid $transparent-filter-color;
        color: $transparent-filter-color;
        background-color: $transparent-filter-bkg-color;
        &.filter-button--is-active {
          color: $transparent-filter-color-selected;
          background-color: $transparent-filter-bkg-color-selected;
        }
      }
      @media screen and (min-width: $breakpoint-desktop) {
        visibility: hidden;
        display: none;
      }
    }
    &__spotlight-filters {
      overflow: hidden;
      @include filters-container();
      @media screen and (min-width: $breakpoint-desktop) {
        overflow: auto;
        flex-wrap: wrap;
      }
    }
    &__spotlight-filters-container {
      display: flex;
      flex-direction: column;
      gap: 6px;
      flex: 1;
      justify-content: flex-start;
      @media screen and (min-width: $breakpoint-desktop) {
        max-width: 75%;
        flex-direction: row;
        align-items: center;
        gap: 18px;
      }
    }
    &__price-select {
      display: none;
      visibility: hidden;
      &--is-ilottery {
        color: white;
      }
      @media screen and (min-width: $breakpoint-desktop) {
        display: flex;
        visibility: visible;
        align-items: center;
        gap: 6px;
      }
    }
  }
  &__meta {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $font-body;
    box-sizing: border-box;
    &--mobile {
      @media screen and (min-width: $breakpoint-desktop) {
        display: none;
        visibility: hidden;
      }
    }
    &--desktop {
      display: none;
      visibility: hidden;
      @media screen and (min-width: $breakpoint-desktop) {
        display: flex;
        visibility: visible;
      }
      [class*='__num-games'] {
        font-size: 20px;
      }
    }
  }
  &__meta-buttons {
    width: 100%;
    display: flex;
    column-gap: 16px;
    padding-top: 18px;
    @media screen and (min-width: $breakpoint-desktop) {
      width: auto;
    }
  }
  &__num-games {
    margin: 0;
    font-size: 14px;
    font-family: $obviously-wide-font;
    font-weight: bold;
    padding-bottom: 8px;
    @media screen and (min-width: $breakpoint-desktop) {
      padding-bottom: 12px;
    }
    &--is-ilottery {
      color: white;
    }
  }
  &__reset {
    font-weight: bold;
    font-family: $rubik-font;
    &:disabled {
      opacity: 1;
      cursor: not-allowed;
      color: color.adjust(black, $lightness: 30%);
    }
    &--is-ilottery {
      color: white;
      opacity: 0.5;
      &:disabled {
        opacity: 0.5;
        color: white;
      }
    }
  }
  &__desktop-sort {
    max-width: 33%;
    display: flex;
    column-gap: 8px;
    align-items: center;
    font-family: $rubik-font;
    &-label {
      text-wrap: nowrap;
      font-family: $rubik-font;
      &--is-ilottery {
        color: white;
      }
    }
    & > *:last-child {
      min-width: 100px;
    }
  }
}
