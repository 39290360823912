@use '../../constants.scss' as *;
.table {
  $table-border-radius: 12px;
  width: 100%;
  font-family: $font-body;
  border-collapse: collapse;
  position: relative;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15);
  border-radius: $table-border-radius;
  &__body {
    display: none;
    visibility: hidden;
    @media screen and (min-width: $breakpoint-tablet) {
      display: table-row-group;
      visibility: visible;
    }
  }
  &__body-row {
    &:nth-child(even) {
      background-color: #e8f1f6;
    }
    &:nth-child(odd) {
      background-color: #d0e1eb;
    }
    &--no-footer {
      &:last-of-type {
        td {
          &:first-child {
            border-bottom-left-radius: $table-border-radius;
          }
          &:last-child {
            border-bottom-right-radius: $table-border-radius;
          }
        }
      }
    }
  }
  &__cell {
    padding: 6px 4px;
    &:first-child {
      padding-left: 16px;
    }
    &--multi {
      display: none;
      visibility: hidden;
      @media screen and (min-width: $breakpoint-tablet) {
        display: table-cell;
        visibility: visible;
      }
    }
    &--stacked {
      padding: 0 16px;
      @media screen and (min-width: $breakpoint-tablet) {
        display: none;
        visibility: hidden;
      }
      &:nth-child(even) {
        text-align: right;
      }
    }
    &--stacked-header {
      font-weight: bold;
    }
    &--single-footer {
      font-weight: 500;
      background-color: #ffffff;
      border-bottom-left-radius: $table-border-radius;
      border-bottom-right-radius: $table-border-radius;
      text-align: center;
    }
  }
  &__cell-text {
    display: block;
  }
  &__container {
    width: 100%;
  }
  &__header-row {
    display: none;
    visibility: hidden;
    @media screen and (min-width: $breakpoint-tablet) {
      display: table-row;
      visibility: visible;
    }
    th {
      &:first-of-type {
        border-top-left-radius: $table-border-radius;
      }
      &:last-of-type {
        border-top-right-radius: $table-border-radius;
      }
      color: #ffffff;
      background-color: #276191;
      position: sticky;
      top: 0;
      text-align: left;
      font-weight: bold;
    }
  }
  &__mobile-header {
    @media screen and (min-width: $breakpoint-tablet) {
      display: none;
      visibility: hidden;
    }
  }
  &__footer-row {
    font-weight: 500;
    background-color: #ffffff;
    display: none;
    visibility: hidden;
    @media screen and (min-width: $breakpoint-tablet) {
      display: table-row;
      visibility: visible;
    }
    td {
      &:first-child {
        border-bottom-left-radius: $table-border-radius;
      }
      &:last-child {
        border-bottom-right-radius: $table-border-radius;
      }
    }
  }
  &__footer-stacked-row {
    font-weight: 500;
    background-color: #ffffff;
    display: table-row;
    visibility: visible;
    @media screen and (min-width: $breakpoint-tablet) {
      display: none;
      visibility: hidden;
    }

    &:nth-child(2),
    &:last-child {
      td {
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
    &:last-child {
      td {
        &:first-child {
          border-bottom-left-radius: $table-border-radius;
        }
        &:last-child {
          border-bottom-right-radius: $table-border-radius;
        }
      }
    }
  }
  &__row-group {
    // mobile "rows"
    tr {
      border-bottom: 1px solid rgba(137, 137, 137, 0.2);
      td {
        padding-top: 12px;
        padding-bottom: 12px;
      }
      &:last-child {
        border-bottom: none;
      }
      @media screen and (min-width: $breakpoint-tablet) {
        border-bottom: none;
      }
    }
    &:nth-child(even) {
      background-color: #e8f1f6;
    }
    &:nth-child(odd) {
      background-color: #d0e1eb;
    }
    &:nth-of-type(2) {
      // first mobile body row
      tr:first-child {
        td {
          &:first-child {
            border-top-left-radius: $table-border-radius;
          }
          &:nth-child(2) {
            border-top-right-radius: $table-border-radius;
          }
        }
      }
    }
    &--no-footer {
      // last stacked (mobile) row
      &:last-of-type {
        tr:last-child {
          td {
            &:first-child {
              border-bottom-left-radius: $table-border-radius;
            }
            &:last-child {
              border-bottom-right-radius: $table-border-radius;
            }
          }
        }
      }
    }
  }
}
