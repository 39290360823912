@use '../../constants.scss' as *;
.page {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 100px;
  box-sizing: border-box;
  max-width: $max-content-width;
  outline: none;
  min-height: 50vh;
  &__title {
    text-align: center;
    margin: 24px 0;
    font-size: 30px;
    color: $header-color;
    font-family: $font-headers;
  }
}
