@use '../../constants.scss' as *;
@use 'sass:color';

.action-menu {
  font-family: $rubik-font;
  &__dots {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
  }
  &__content {
    box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    background-color: white;
    min-width: 244px;
    overflow: hidden;
  }
  &__trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 18px;
    border-radius: 4px;
    background-color: transparent;
    width: 26px;
    &:hover {
      background-color: #d8d8d83a;
    }
  }
}
