@use '../../constants.scss' as *;
.horizontal-navigation-category {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;

  white-space: nowrap;
  text-decoration: none;
  font-family: $rubik-font;
  font-size: 14px;
  font-weight: 700;
  background-color: #f6f6f6;

  overflow-x: auto;
  @media screen and (min-width: $max-content-width) {
    overflow-x: visible;
    margin-left: -16px;
  }

  &__links {
    display: flex;
    max-width: $max-content-width;
    width: 100%;
    margin: auto;
    padding: 0;
    list-style-type: none;
  }
  &__link-li {
    padding: 17px 16px;
  }
  &__link {
    text-decoration: none;
    color: #003552;
  }
}
