@use '../../constants.scss' as *;
@use 'sass:color';

.modal {
  &__default-close-button {
    position: absolute;
    background-color: transparent;
    border: none;
    border-radius: 100%;
    right: 10px;
    top: 10px;
    cursor: pointer;
    padding: 4px;
    display: flex;
    &:hover {
      background-color: color.adjust(white, $lightness: -15%);
    }
  }
  &__content {
    width: 100%;
    max-width: $breakpoint-tablet;
    border-radius: 4px;
    box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.15);
    background-color: white;
    box-sizing: border-box;
    overflow-y: auto;
    position: fixed;
    z-index: $z-index-modal;
    top: 50%;
    left: 50%;
    max-height: 100vh;
    transform: translate(-50%, -50%);
    &--mobile-fullscreen {
      @media screen and (max-width: $breakpoint-tablet) {
        transform: translate(0, 0);
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
      }
    }
  }
  &__overlay {
    background-color: grey;
    opacity: 0.25;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.modal-trigger-button {
  cursor: pointer;
  border: none;
  padding: 0;
  background: none;
}
