@use '../../constants.scss' as *;
.column-layout {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  @media screen and (min-width: $breakpoint-tablet) {
    grid-template-columns: repeat(min(var(--num-columns), 2), 1fr);
  }
  @media screen and (min-width: $breakpoint-desktop) {
    grid-template-columns: repeat(var(--num-columns), 1fr);
  }
  &--one-third-two-thirds {
    @media screen and (min-width: $breakpoint-tablet) {
      grid-template-columns: 1fr 2fr;
    }
  }
  &--two-thirds-one-third {
    @media screen and (min-width: $breakpoint-tablet) {
      grid-template-columns: 2fr 1fr;
    }
  }
  &__accordion__cta {
    align-self: flex-end;
  }
  &__column {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }
}
