@use '../../constants.scss' as *;
.date-selector-with-arrows {
  display: flex;
  align-items: center;
  &__arrow {
    &:disabled {
      opacity: 0.5;
    }
  }
}
