@use '../../constants.scss' as *;
.jackpot-card {
  $min-card-width: 270px;
  $min-card-height: 270px;
  height: 100%;
  min-height: $min-card-height;
  min-width: $min-card-width;
  padding: 12px;
  box-sizing: border-box;
  display: flex;
  box-shadow: 0px 5px 10px #00000026;
  border-radius: 5px;
  font-family: $rubik-font;
  background-color: #ffffff;
  &--is-loading,
  &--has-error {
    align-items: center;
    justify-content: center;
  }
  &__cta {
    min-width: 112px;
    min-height: 40px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: #ffffff;
    box-sizing: border-box;
    padding: 6px 10px;
    background: #186259;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 700;
    font-family: $rubik-font;

    svg > path {
      fill: #ffffff;
    }
  }
  &__info-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  &__jackpot-annotation {
    color: #231f20;
    font-size: 12px;
    font-weight: 700;
  }
  &__jackpot-text {
    color: #231f20;
    font-size: 12px;
    font-weight: 400;
  }
  &__jackpot-value {
    color: #d84127;
    font-family: $obviously-narrow-font;
    font-size: 30px;
    font-weight: 600;
    display: inline;
    width: fit-content;
    &--is-pending {
      font-style: italic;
    }
  }
  &__jackpot-info {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
  &__game-info {
    display: flex;
    justify-content: center;
    // "current" jackpot by default
    & > * {
      img {
        border-radius: 4px;
        max-height: 60px;
        object-fit: contain;
      }
    }
    &--is-estimated {
      justify-content: space-between;
      gap: 4px;
      & > * {
        max-width: $min-card-width * 0.65;
        justify-content: flex-start;
        img {
          width: 80%;
        }
      }
    }
  }
  &__next-drawing-info {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    font-family: $obviously-font;
    font-size: 12px;
    font-weight: 600;
    white-space: nowrap;
  }
  &__next-drawing-text {
    font-family: $obviously-narrow-font;
    font-weight: 800;
  }
  &__primary-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    gap: 14px;
    width: 100%;
  }
  &__promo-text {
    border-top: 1px solid #dddfe1;
    padding-top: 12px;
    display: flex;
    color: #181919;
    font-size: 10px;
    font-weight: 400;
  }
}
