@use '../../constants.scss' as *;
.game-image-link {
  &__image {
    border-radius: 4px;
    overflow: hidden;
    img {
      max-width: 100%;
      width: auto;
      max-height: 80px;
    }
  }
}
