@use '../../constants.scss' as *;
.page-not-found {
  display: flex;
  font-family: $font-body;
  justify-content: center;
  margin-top: 200px;
  h1 {
    font-size: 40px;
    margin: 0;
    margin-bottom: 12px;
  }
  &__info {
    border-radius: 100%;
    background-color: #dddfe1;
    height: $breakpoint-small-mobile;
    width: $breakpoint-small-mobile;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 8px;
  }
  &__home-link {
    color: $inline-link-color;
    font-weight: bold;
  }
}
