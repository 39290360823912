@use '../../constants.scss' as *;
@use 'sass:color';

.new-e-instants {
  min-height: 200px;
  @include ilottery-background;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  @media screen and (min-width: $breakpoint-desktop) {
    padding: 46px 20px;
  }
  &__desktop-container {
    display: flex;
    visibility: visible;
    flex-direction: column;
    gap: 10px;
    margin: auto;
    max-width: $max-content-width;
    width: 100%;
  }
  &__desktop-tile-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;
    box-sizing: border-box;
    min-height: 200px;
    & > * {
      width: 100%;
      min-height: 200px;
      min-width: 230px;
      max-width: 300px;
    }
  }
  &__mobile-container {
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: 100%;
    & > * {
      width: 100%;
    }
  }
  &__mobile-tile-container {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 4px;
    & > * {
      @media screen and (min-width: 300px) {
        min-height: 250px;
        min-width: 300px;
      }
    }
  }
  &__title {
    font-size: 19px;
    color: white;
    font-family: $obviously-wide-font;
    font-weight: bold;
    margin: 0;
  }
}
