@use '../../constants.scss' as *;
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  box-sizing: border-box;
  border-left: 5px solid rgba(237, 28, 36, 1);
  animation-name: spin;
  animation-iteration-count: infinite;
  animation-duration: 0.5s;
  animation-timing-function: linear;
}
