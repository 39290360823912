@use '../../constants.scss' as *;
@use 'sass:color';

.link-group-showcase {
  $link-color: #2b3037;
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  gap: 20px;
  align-self: stretch;
  background: linear-gradient(167deg, #ecedea 0%, #f8f8f6 50%, #dcdbd8 100%);
  &--blue {
    @include ilottery-background;
  }
  &--yellow {
    background: linear-gradient(180deg, #f8a731 0%, 50%, #fac931 100%);
  }
  &__title-display {
    color: var(--title-color);
    font-size: 20px;
    font-family: $obviously-wide-font;
    font-weight: 700;
    color: $link-color;
    &--white {
      color: white;
    }
  }
  &__title-link {
    display: flex;
    align-items: center;
    column-gap: 4px;
    text-decoration: none;
    color: $link-color;
    &:hover {
      text-decoration: underline;
    }
  }
  &__links {
    display: flex;
    gap: 8px;
  }
  &__link-group {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    width: 102px;
    align-items: center;
    color: $link-color;
  }
  &__link-icon {
    background-color: white;
    width: 82px;
    height: 82px;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    & > *:last-child {
      min-height: 82px;
    }
    &--shadow {
      box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.2);
    }
    &--red {
      background-color: #c74a27;
    }
    &--black {
      background-color: #1f1f1f;
    }
  }
  &__link-display {
    color: $link-color;
    text-align: center;
    font-family: $font-body;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    &--white {
      color: white;
    }
  }
}
