@use '../../constants.scss' as *;
@keyframes slideDown {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    opacity: 1;
    height: var(--radix-collapsible-content-height);
  }
  to {
    height: 0;
    opacity: 0;
  }
}

.collapsible {
  position: relative;
  &__header-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    column-gap: 6px;
  }
  &__trigger {
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
    transition: 0.2s transform ease-in-out;
    transform: rotate(0);
    transform-origin: 50% 45%;
    cursor: pointer;
    &--is-open {
      transform: rotate(-180deg);
    }
  }
  &__content {
    overflow: hidden;
    transform-origin: top center;
    &[data-state='open'] {
      animation: slideDown 300ms ease-in-out;
    }
    &[data-state='closed'] {
      animation: slideUp 300ms ease-in-out;
    }
  }
}
