@use '../../constants.scss' as *;
.button {
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
