@use '../../constants.scss' as *;
@use 'sass:color';

#mobile-navigation__close-button {
  cursor: pointer;
  background-color: transparent;
  border-radius: 100%;
  display: flex;
  padding: 4px;
  &:hover {
    background-color: color.adjust(white, $lightness: -15%);
  }
}

.mobile-navigation {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  min-height: 100vh;
  padding-bottom: 40px;
  @media screen and (min-width: $breakpoint-desktop) {
    display: none;
    visibility: hidden;
  }
  &__categories {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
  }
  &__content-header {
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding: 16px;
    box-sizing: border-box;
    width: 100%;
  }
}

.desktop-navigation {
  display: none;
  visibility: hidden;
  column-gap: 50px;
  @media screen and (min-width: $breakpoint-desktop) {
    display: flex;
    visibility: visible;
  }
  &__category__header {
    font-family: $font-body;
  }
  &__category__content {
    font-family: $font-body;
    box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    top: 40px;
    z-index: 1;
    position: absolute;
  }
  &__standalone-link {
    color: $nav-link-color;
  }
}
