@use '../../constants.scss' as *;
.game-action-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  &__content {
    padding: 10px 20px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    box-sizing: border-box;
    max-width: 260px;
  }
  &__ctas {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    & > *:last-child {
      max-width: 50%;
      text-align: right;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    gap: 4px;
    &-text {
      font-family: $obviously-font;
      font-weight: 600;
      font-size: 12px;
      display: inline-block;
    }
  }
  &__primary-cta {
    background-color: $primary-cta-bkg-color;
    color: $primary-cta-color;
    padding: 12px 14px;
    border-radius: 100px;
    font-weight: bold;
    font-size: 14px;
  }
  &__secondary-cta {
    color: $secondary-cta-color;
    font-weight: bold;
    font-size: 14px;
  }
  &__series {
    padding: 10px 20px;
    background-color: var(--series-bkg-color, $ilottery-blue);
    color: var(--series-color, white);
    font-family: $obviously-narrow-font;
    font-size: 12px;
    font-weight: 600;
  }
  &__tagline {
    font-family: $rubik-font;
    font-size: 10px;
  }
}
