@use '../../constants.scss' as *;
.async-image {
  width: 100%;
  display: flex;
  justify-content: center;
  &__loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3f5f6;
    min-height: var(--min-height, 100px);
    min-width: var(--min-width);
    width: 100%;
  }
  &__img {
    width: 100%;
    max-width: fit-content;
    &--not-loaded {
      display: none;
      max-width: unset;
    }
  }
}
