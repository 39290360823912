@use '../../constants.scss' as *;
@use 'sass:color';

.feed {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  align-items: center;
  &__header {
    font-family: $font-headers;
    margin: 0;
  }
  &__header-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__feed-items {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    &--tile {
      display: grid;
      gap: 12px;
      grid-template-columns: 1fr;
      @media screen and (min-width: $breakpoint-tablet) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (min-width: $breakpoint-desktop) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
  &__show-more {
    @include primary-cta;
    &:hover {
      background-color: color.adjust($primary-cta-bkg-color, $lightness: -10%);
      &:disabled {
        background-color: $primary-cta-bkg-color;
      }
    }
  }
}
