@use '../../constants.scss' as *;
.email-subscribe {
  background-color: white;
  #email-subscribe__loading {
    width: 26px;
    height: 26px;
    border-left: 3px solid white;
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: $max-content-width;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 32px 16px;
    @media screen and (min-width: $breakpoint-desktop) {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
  }
  &__header {
    font-size: 20px;
    font-family: $obviously-wide-font;
    font-weight: bold;
    margin: 0;
  }
  &__first-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    & > *:first-child {
      width: 100%;
    }
  }
  &__form {
    display: flex;
    gap: 8px;
    width: 100%;
    @media screen and (min-width: $breakpoint-desktop) {
      max-width: 500px;
    }
    &--email-submitted {
      flex-direction: column;
    }
    & > * {
      width: 100%;
    }
  }
  &__subheader {
    font-size: 15px;
    font-family: $rubik-font;
  }
  &__submit {
    @include primary-cta;
    padding: 10px 20px;
    margin-top: 22px;
    max-height: 45px;
    border-radius: 25px;
  }
  &__text {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__success-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    padding: 20px;
  }
  &__success-header {
    font-family: $obviously-wide-font;
    font-size: 20px;
    font-weight: bold;
  }
  &__success-text {
    font-family: $rubik-font;
    font-size: 15px;
  }
  &__success-close {
    @include primary-cta;
    padding: 10px 20px;
  }
}
