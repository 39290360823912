@use '../../constants.scss' as *;
.past-results-cell {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 16px;
  gap: 6px;
  font-family: $font-body;
  box-sizing: border-box;
  width: 100%;
  @media screen and (min-width: $breakpoint-desktop) {
    display: table-row;
    vertical-align: middle;
    td {
      padding: 12px 8px;
      &:first-of-type {
        padding-left: 16px;
      }
      &:last-of-type {
        padding-right: 16px;
      }
    }
  }
  &--rapid {
    @media screen and (min-width: $breakpoint-desktop) {
      display: table-row;
      vertical-align: middle;
      td {
        min-width: 80px;
        &:nth-of-type(2) {
          padding-left: 16px;
        }
      }
    }
  }
  &--draw {
    &-label {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      td {
        .winning-numbers {
          align-items: flex-start;
          &__content {
            flex-direction: column-reverse;
            gap: 0px;
          }
        }
      }
    }
  }
  &__your-winnings {
    &--winner {
      font-weight: bold;
    }
  }

  &:nth-of-type(odd) {
    background-color: #e8f1f6;
  }
  &:nth-of-type(even) {
    background-color: #d0e1eb;
  }
  &__draw-jackpot {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  &__jackpot-won-text {
    font-style: italic;
  }
  &__rapid-draw-number-and-time {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @media screen and (min-width: $breakpoint-desktop) {
      display: none;
      visibility: hidden;
    }
  }
  &__rapid-draw-number,
  &__rapid-draw-time {
    display: none;
    visibility: hidden;
    @media screen and (min-width: $breakpoint-desktop) {
      display: table-cell;
      visibility: visible;
    }
  }
  .winning-numbers {
    justify-content: flex-start;
    padding-left: 0;

    &__numbers {
      justify-content: left;
    }
  }
}

.past-results-table {
  width: 100%;
  border-collapse: collapse;
  font-family: $font-body;
  margin-top: 24px;
  &__clickable-header {
    display: flex;
    gap: 16px;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
  }
  &__header-row {
    font-size: 16px;
    color: white;
    background-color: #276191;
    border-bottom: 1px solid #676b72;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    td {
      padding: 16px;
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none;
      @media screen and (min-width: $breakpoint-desktop) {
        padding: 16px 8px;
        &:first-of-type {
          padding-left: 16px;
        }
        &:last-of-type {
          padding-right: 16px;
        }
      }
      &:first-child {
        border-top-left-radius: 5px;
        overflow: hidden;
      }
      &:last-child {
        border-top-right-radius: 5px;
        overflow: hidden;
      }
    }
  }
  &__rapid-mobile-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    box-sizing: border-box;
    align-items: flex-start;
  }
  &__rapid-mobile-subheader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__no-results {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    font-size: 16px;
  }
  tbody {
    @media screen and (min-width: $breakpoint-desktop) {
      tr {
        &:last-child {
          td {
            &:first-child {
              border-bottom-left-radius: 5px;
            }
            &:last-child {
              border-bottom-right-radius: 5px;
            }
          }
        }
      }
    }
  }
}
