@use '../../constants.scss' as *;
@use 'sass:color';

.calendar-month {
  display: grid;
  position: absolute;
  grid-template-columns: repeat(7, 1fr);
  left: 0;
  top: 0;
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
  font-family: $font-body;
  &--prev {
    transform: translateX(-100%);
  }
  &--next {
    transform: translateX(100%);
  }
  &--current {
    position: relative;
  }
  &__day-header {
    display: flex;
    width: 32px;
    height: 32px;
    flex-direction: column;
    justify-content: center;
    color: #878b93;
    text-align: center;
    font-family: $font-body;
    font-size: 12px;
  }
}

.calendar-day {
  font-family: $font-body;
  border-radius: 50px;
  width: 32px;
  height: 32px;
  color: black;
  transition: opacity 0.2s;
  &:hover {
    background-color: color.adjust(black, $lightness: 90%);
  }
  &--is-selected {
    background-color: black;
    color: white;
    &:hover {
      background-color: black;
    }
  }
  &--is-disabled {
    opacity: 0.5;
    color: color.adjust(black, $lightness: 50%);
    cursor: not-allowed;
  }
  &--is-in-range {
    background-color: color.adjust(black, $lightness: 90%);
  }
}
