@use '../../constants.scss' as *;
.footer {
  color: white;
  background-color: rgb(20, 20, 20);
  background-image: url('../../assets/dot-pattern.png');
  background-repeat: repeat-x;
  background-position: bottom;
  font-family: $rubik-font;
  box-sizing: border-box;
  padding: 50px 16px 16px 16px;
  width: 100%;
  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    max-width: 500px;
    @media screen and (min-width: 1400px) {
      justify-content: space-between;
      gap: 10px;
      max-width: $max-content-width;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
  &__column {
    display: flex;
    flex-direction: column;
    gap: 6px;
    @media screen and (min-width: $breakpoint-desktop) {
      gap: 10px;
    }
  }
  &__copyright {
    font-size: 12px;
    text-align: center;
    display: inline-block;
    @media screen and (min-width: $breakpoint-desktop) {
      font-size: 14px;
    }
  }
}

.footer-contact-block {
  display: flex;
  flex-direction: column;
  gap: 6px;
  @media screen and (min-width: $breakpoint-desktop) {
    gap: 20px;
  }
  &__title {
    font-family: $obviously-wide-font;
    font-weight: 600;
    margin: 0;
    font-size: 14px;
    color: #ebac11;
  }
  &__group {
    gap: 6px;
    display: flex;
    flex-direction: column;
    color: white;
  }
  &__group-title {
    color: #ebac11;
    @media screen and (min-width: $breakpoint-desktop) {
      color: white;
    }
  }
  &__link {
    color: white;
  }
  &__chat-ilottery {
    background-color: $accessible-red;
    font-weight: bold;
    font-family: $rubik-font;
    font-size: 12px;
    color: white;
    border-radius: 25px;
    padding: 6px 16px;
    max-width: 112px;
  }
}

.footer-desktop {
  display: flex;
  justify-content: space-between;
  gap: 26px;
  width: 100%;
  max-width: $max-content-width;

  &__column {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.footer-icon-group {
  display: flex;
  gap: 24px;
  flex-direction: column;
  justify-content: center;
  max-width: 170px;
  @media screen and (min-width: $breakpoint-desktop) {
  }
  &__icons {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
}

.footer-link-group {
  display: flex;
  flex-direction: column;
  gap: 6px;
  @media screen and (min-width: $breakpoint-desktop) {
    gap: 20px;
  }
  &__title {
    font-family: $obviously-wide-font;
    margin: 0;
    font-size: 14px;
    color: #ebac11;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 6px;

    list-style-type: none;
    font-size: 14px;
    font-family: $rubik-font;
    padding: 0;
    margin: 0;
    @media screen and (min-width: $breakpoint-desktop) {
      line-height: 2.5;
    }
  }
  &__link {
    color: white;
  }
  &__standalone-links {
    display: flex;
    flex-direction: column;
    gap: 6px;
    @media screen and (min-width: $breakpoint-desktop) {
      gap: 20px;
    }
  }
}

.footer-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    width: 100%;
  }
  &__column {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }
}
