@use '../../constants.scss' as *;
.cta {
  display: inline-block;
  width: fit-content;
  font-family: $font-cta;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 12px 16px;
  &--primary {
    @include primary-cta;
  }
  &--secondary {
    @include secondary-cta;
  }
}
