@use '../../constants.scss' as *;
.sliding-carousel {
  max-width: calc(100vw - 16px);
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 0 16px;
  position: relative;
  @media screen and (min-width: $breakpoint-desktop) {
    margin: 0 24px;
    max-width: calc(100vw - 24px);
  }
  @media screen and (min-width: calc($max-content-width + 24px)) {
    margin: 0;
  }
  &--with-scroll {
    margin-right: 0;
  }
  &__items {
    display: grid;
    grid-template-rows: 1fr;
    grid-auto-flow: column;
    gap: 16px;
    overflow-x: auto;
    width: 100%;
    scroll-behavior: smooth;
    box-sizing: border-box;
    padding-left: 10px;
    padding-bottom: 20px;
    &--with-scroll {
      & > *:last-child {
        padding-right: 16px;
        @media screen and (min-width: $breakpoint-desktop) {
          padding-right: 24px;
        }
      }
    }
  }
  &__items-container {
    &--with-scroll {
      position: relative;
      @mixin remaining-scroll-shadow($direction) {
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        content: '';
        width: 30px;
        height: calc(100% - 20px);
        top: 0;
        position: absolute;
        background-image: linear-gradient(
          to $direction,
          rgba(78, 78, 78, 0.3),
          transparent 33%
        );
      }
      &::before {
        @include remaining-scroll-shadow(right);
        left: 0;
      }
      &::after {
        @include remaining-scroll-shadow(left);
        right: 0;
      }
    }
    &--with-left-scroll-remaining {
      &::before {
        opacity: 1;
      }
    }
    &--with-right-scroll-remaining {
      &::after {
        opacity: 1;
      }
    }
  }
  &__title {
    font-family: $font-headers;
    color: $header-color;
    font-size: 22px;
    padding-left: 10px;
    margin: 0;
  }
  &__title-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover {
      text-decoration-color: $header-color;
    }
    @media screen and (min-width: $breakpoint-desktop) {
      align-items: baseline;
    }
  }
  &__title-link-helper {
    display: flex;
    align-items: center;
    margin-right: 16px;
    font-size: 18px;
    font-family: $font-body;
    color: #2b3037;
    gap: 4px;
    font-weight: 600;
    @media screen and (min-width: $breakpoint-desktop) {
      margin-right: 24px;
    }
  }
  &__title-link-helper-text {
    display: none;
    visibility: none;
    @media screen and (min-width: $breakpoint-desktop) {
      display: inline-block;
      visibility: visible;
    }
  }
}
