@use '../../constants.scss' as *;
.marketing-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: var(--modal-bkg-color, #ffffff);
  box-sizing: border-box;
  padding: 12px;
  gap: 12px;
  padding-bottom: 30px;
  &__title {
    margin: 0;
    color: var(--modal-title-color, #000000);
    font-family: $font-headers;
    font-size: 30px;
  }
}
