@use '../../constants.scss' as *;
.cms-accordion {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 5px 10px #00000026;
  padding: 10px;
  box-sizing: border-box;
  &__accordion-content {
    border-top: 1px solid #dddfe1;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  &__accordion-header {
    font-family: $obviously-font;
    font-size: 16px;
    padding: 10px;
    font-weight: 600;
    box-sizing: border-box;
  }
}
