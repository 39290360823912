@use '../../constants.scss' as *;
@use 'sass:color';

.account-menu {
  position: relative;

  @mixin account-dropdown {
    @include ilottery-background;
    z-index: 1;
    min-width: 230px;
    padding: 8px;
    border-radius: 5px;
    margin: 0;
    box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.15);
    position: absolute;
    right: 0;
  }
  &__account-link {
    padding: 2px;
    border-radius: 4px;
    &:hover {
      background-color: color.adjust(white, $lightness: 15%);
    }
  }
  &__auth-buttons-container {
    @include account-dropdown;
    background: white;
  }
  &__button {
    width: 100%;
    text-align: left;
    font-family: $obviously-wide-font;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;

    &:hover {
      text-decoration: underline;
    }
  }
  &__sign-out-button {
    font-family: $rubik-font;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    padding: 8px 12px;
    border-radius: 25px;
    border: 1px solid #ffffff;
  }
  &__divider {
    border-top: 0.5px solid #ffffff;
  }
  &__list {
    list-style-type: none;
    position: relative;
    left: -8px;
    width: calc(100% + 16px);
    box-sizing: border-box;
    margin: 0;
    padding: 0 8px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  &__menu-container {
    @include account-dropdown;
    padding-bottom: 0;
  }
  &__item {
    padding: 16px 0;
    border-top: 0.5px solid #ffffff;
  }
  &__player-container {
    position: relative;
  }
  &__player-notification {
    position: absolute;
    top: -15px;
    right: -5px;
  }
  &__player-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
    color: white;
    padding-top: 8px;
    padding-bottom: 16px;
  }
  &__player-balance {
    font-family: $rubik-font;
    font-size: 14px;
  }
  &__player-name {
    font-family: $obviously-wide-font;
    font-size: 14px;
    font-weight: bold;
  }
}
