@use '../../constants.scss' as *;
.prizes-remaining {
  $list-gap: 24px;
  @mixin prizes-remaining-loading-container($min-height: 300px) {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: $min-height;
  }
  &__state {
    @include prizes-remaining-loading-container;
  }
  &__form-loading {
    @include prizes-remaining-loading-container(370px);
  }

  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $list-gap;

    @media screen and (min-width: $breakpoint-desktop) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &__lists-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $list-gap;
  }
  &__disclaimer {
    font-family: $rubik-font;
    padding-top: 24px;
    font-size: 22px;
    line-height: 1.3;
  }
}
