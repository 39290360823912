@use '../../constants.scss' as *;
@use 'sass:color';

.feed-item {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15);
  background-color: white;
  border-radius: 8px;
  padding: 12px;
  box-sizing: border-box;
  &__content {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 12px;
    & > *:first-child {
      display: block;
      width: auto;
      border-radius: 5px;
      overflow: hidden;
    }
    &--no-image {
      grid-template-columns: 1fr;
      gap: 8px;
      justify-items: end;
      & > *:first-child {
        justify-self: start;
      }
    }
  }
  &__date {
    font-style: italic;
    font-family: $font-body;
    font-size: 14px;
  }
  &__header {
    font-family: $font-headers;
    font-size: 24px;
    margin: 0;
  }
  &__preview-group {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    &--with-cta {
      & > *:last-child {
        align-self: flex-end;
      }
    }
  }
  &--tile {
    .feed-item {
      &__content {
        gap: 8px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
      }
      &__header {
        text-align: center;
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
        background-color: white;
        border-radius: 8px;
        padding: 8px 12px;
        position: relative;
        left: -12px;
        top: -12px;
        width: 100%;
      }
      &__date {
        color: color.adjust(black, $lightness: 30%);
        font-weight: 300;
        text-align: center;
      }
      &__preview-group {
        margin-top: 8px;
        align-items: center;
        & > *:last-child {
          align-self: unset;
        }
      }
    }
  }
}
