@use '../../constants.scss' as *;
@keyframes contentSlideInFromLeft {
  from {
    transform: translate(-100%);
  }
  to {
    transform: translate(0);
  }
}

@keyframes contentSlideInFromRight {
  from {
    transform: translate(100%);
  }
  to {
    transform: translate(0);
  }
}

@keyframes contentSlideOutToLeft {
  from {
    transform: translate(0);
  }
  to {
    transform: translate(-100%);
  }
}

@keyframes contentSlideOutToRight {
  from {
    transform: translate(0);
  }
  to {
    transform: translate(100%);
  }
}

.sidebar {
  &__content {
    width: 100%;
    max-width: 500px;
    position: fixed;
    z-index: $z-index-sidebar;
    top: 0;
    bottom: 0;
    overflow-y: auto;

    &--left {
      left: 0;
      &[data-state='open'] {
        animation: contentSlideInFromLeft 200ms ease-out;
      }
      &[data-state='closed'] {
        animation: contentSlideOutToLeft 200ms ease-out;
      }
    }
    &--right {
      right: 0;
      &[data-state='open'] {
        animation: contentSlideInFromRight 200ms ease-out;
      }
      &[data-state='closed'] {
        animation: contentSlideOutToRight 200ms ease-out;
      }
    }
  }
  &__overlay {
    background-color: grey;
    opacity: 0.25;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
