@use '../../constants.scss' as *;
.account-deposit-widget {
  &__authenticated {
    display: flex;
    justify-content: space-between;
    background-color: $primary-navy;
    font-family: $rubik-font;
    color: white;
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
  }
  &__account-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  &__deposit-button {
    @include primary-cta;
  }
  &__name {
    font-family: $obviously-wide-font;
    font-weight: bold;
    font-size: 16px;
  }
}
