@use '../../constants.scss' as *;
.mobile-app-card {
  $breakpoint-mobile-app-card: 850px;
  display: flex;
  max-width: $breakpoint-mobile-app-card;
  margin: 18px auto;
  width: calc(100% - 32px);
  border-radius: 5px;
  background-color: #ffffff;

  @media screen and (max-width: $breakpoint-desktop) {
    flex-direction: column;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 42px 42px 46px 42px;
    color: #000000;
    font-family: $rubik-font;
    @media screen and (max-width: $breakpoint-desktop) {
      padding: 20px;
    }
  }
  &__title {
    font-family: $obviously-wide-font;
    font-size: 19px;
    font-weight: 700;
    margin: 0 0 8px 0;
    @media screen and (min-width: 450px) {
      white-space: nowrap;
    }
  }
  &__description {
    font-size: 15px;
    font-weight: 400;
    padding-bottom: 16px;
  }
  &__links {
    display: flex;
    gap: 15px;
  }
  &__badge {
    height: 40px;
    img {
      object-fit: contain;
    }
  }
  &__app-store-link {
    max-width: 118px;
  }
  &__play-store-link {
    max-width: 133px;
  }

  &__image {
    position: relative;
    background-color: #f4f4f6;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
      border-radius: 5px 0 0 5px;
      @media screen and (min-width: $breakpoint-desktop) {
        position: absolute;
        top: -9%;
      }
    }
  }
}
