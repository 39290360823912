@use '../../constants.scss' as *;
body {
  margin: 0;
  width: 100%;
  height: 100%;
}

h1 {
  color: $header-color;
}

html {
  background-color: #f4f4f6;
}
