@use '../../constants.scss' as *;
#game-collection__page {
  max-width: unset;
  margin-top: 0;
  padding-bottom: 0;
}

@mixin in-store-background {
  background-color: #f4f4f6;
  background-image: url('../../assets/in-store-bkg.webp');
  background-size: cover;
}

.game-collection {
  max-width: $breakpoint-desktop;
  margin: auto;
  padding: 0 16px;
  box-sizing: border-box;
  &__subtitle {
    font-family: $rubik-font;
    font-size: 18px;
    text-align: center;
    margin-top: 0;
  }
  &__page {
    padding-top: 30px;
    h1 {
      margin-bottom: 4px;
    }
    &::after {
      @include in-store-background;
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
    &--is-ilottery {
      h1 {
        margin-top: 0;
        color: white;
      }
      [class*='__subtitle'] {
        color: white;
      }
      &::after {
        @include ilottery-background;
        background-size: unset;
      }
    }
  }
  &__carousel-container {
    box-sizing: border-box;
    width: 100%;
  }
  &__image-banner {
    position: relative;
    width: 100vw;
    left: calc(-50vw + 50%);
    background-color: unset;
  }
  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 20px;
  }
}
