@use '../../constants.scss' as *;
@use 'sass:color';

.dot-navigation {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media screen and (min-width: $breakpoint-desktop) {
    justify-content: center;
    gap: 20px;
  }
  &--no-desktop-dots {
    @media screen and (min-width: $breakpoint-desktop) {
      & > *:not(:first-child, :last-child) {
        display: none;
        visibility: hidden;
      }
    }
  }
  &--with-gap {
    gap: 20px;
    justify-content: center;
  }
  &__dot {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    background-color: #dddfe1;
    display: inline-block;
    &--is-selected {
      background-color: #2b3037;
    }
    &--is-light {
      background-color: color.adjust(#dddfe1, $lightness: -15%);
    }
    &--is-light-selected {
      background-color: #fff;
    }
  }
}
