@use '../../constants.scss' as *;
@use 'sass:color';

.youtube-path {
  fill: #fff;
  stroke: #231f20;
  stroke-miterlimit: 10;
  &:hover {
    fill: color.adjust(#fff, $lightness: -15%);
  }
}
