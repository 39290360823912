@use '../../constants.scss' as *;
.sign-in-button {
  background-color: $primary-cta-bkg-color;
  color: $primary-cta-color;
  text-align: center;
  border-radius: 25px;
  padding: 12px 20px;
  font-family: $rubik-font;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  min-width: 103px;
}
