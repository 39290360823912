@use '../../constants.scss' as *;
.winner-cell {
  &__col {
    color: #2b3037;
    font-size: 18px;
    font-weight: 500;
    font-family: $rubik-font;
    padding: 16px;
  }
  &__container {
    &--last {
      float: right;
    }
  }
  &__vertical {
    display: flex;
    flex-direction: column;
    padding: 16px 0px 16px 12px;
    gap: 4px;
  }
  &__date {
    font-size: 16px;
  }
  &__game {
    font-weight: 600;
  }
  &__winner {
    color: #000000;
    font-size: 14px;
  }
  &__prize {
    float: right;
    padding: 16px 12px 16px 10px;
    font-size: 22px;
    font-weight: 600;
  }
  &:nth-of-type(odd) {
    background-color: #e8f1f6;
  }
  &:nth-of-type(even) {
    background-color: #d0e1eb;
  }
}

.winners-table {
  width: 100%;
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 500;
  font-family: $rubik-font;
  background-color: #edeff0;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;

  &__header-row {
    color: #ffffff;
    font-weight: 600;
    background-color: #276191;

    &__col {
      padding: 16px;
      border-bottom: 1px solid #676b72;
    }
    &__container {
      display: flex;
      align-items: center;
      gap: 16px;

      &--clickable {
        cursor: pointer;
      }
      &--last {
        justify-content: flex-end;
      }
    }
  }
  &__download-button {
    color: #000000;
    font-size: 17px;
    font-weight: 600;
    font-family: $rubik-font;
    padding-bottom: 16px;
  }
}
