@use '../../constants.scss' as *;
.prizes-remaining-form {
  @mixin form-row {
    display: flex;
    flex-direction: column;
    gap: $form-gap;
    width: 100%;
    @media screen and (min-width: $breakpoint-desktop) {
      flex-direction: row;
      align-items: flex-end;
    }
  }
  $form-gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  gap: $form-gap;
  // IMO improve designs; this is a bit much and lowkey ugly
  &__desktop-top-row {
    @include form-row;
  }
  &__desktop-bottom-row {
    @include form-row;
    & > * {
      &:first-child {
        @media screen and (min-width: $breakpoint-desktop) {
          width: 40%; // I'll be real this makes no sense to me
        }
      }
      &:last-child {
        @media screen and (max-width: $breakpoint-desktop) {
          align-self: flex-start; // ...
        }
      }
    }
  }
  &__filter-list-title {
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    font-family: $font-body;
  }
  &__filter-submit-button {
    @include primary-cta;
    padding: 12px 24px;
    border: 1px solid $primary-green;
    border-radius: 25px;
  }
  &__filter-remove-button {
    border-radius: 3px;
    font-weight: bold;
    padding: 0;
  }
  &__meta-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 24px 0;
    font-family: $font-input;
  }
}
