@use '../../constants.scss' as *;
.progressive-jackpots-icon {
  &__cls-1 {
    fill: none;
    stroke: #000;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 8.5px;
  }

  &__cls-2 {
    font-size: 87.17px;
  }

  &__cls-2,
  &__cls-3 {
    font-family: Obviously-Medi, Obviously;
  }

  &__cls-3 {
    font-size: 50.49px;
  }

  &__cls-4 {
    fill: #fff;
  }
}