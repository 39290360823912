@use '../../constants.scss' as *;
.game-nav-stacked {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  background-color: white;
  @media screen and (max-width: $breakpoint-desktop) {
    padding-bottom: 40px;
  }
}
