@use '../../constants.scss' as *;
.scratch-game-details-page {
  &__page-info {
    padding: 0px 8px;
    box-sizing: border-box;
    font-family: $rubik-font;
    color: #2b3037;
    width: 100%;
  }
  &__image-container {
    position: relative;
    display: flex;
    justify-content: center;
  }
  &__image {
    max-width: $breakpoint-average-mobile;
    display: flex;
    justify-content: flex-start;
    img {
      border-radius: 16px;
    }
    @media screen and (min-width: $breakpoint-desktop) {
      justify-content: center;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px 0px;
  }
  &__horizontal {
    display: flex;
    align-items: flex-start;
    gap: 8px;
  }
  &__vertical {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__title {
    font-size: 30px;
    font-weight: 600;
    font-family: $obviously-font;
    margin: 0;
  }
  &__price {
    margin-top: 12px;
  }
  &__prize {
    font-size: 18px;
    color: #676b72;
  }
  &__odds-header {
    font-family: $obviously-font;
    font-size: 24px;
    margin: 0;
  }
  &__results {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  &__label {
    font-size: 14px;
    font-weight: 500;
    color: #676b72;
  }
  &__value {
    font-size: 14px;
    font-weight: 500;
  }
  &__state {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    width: 100%;
  }
  &__disclaimer {
    text-align: center;
    font-family: $rubik-font;
    font-size: 16px;
  }
}
