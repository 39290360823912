@use '../../constants.scss' as *;
.filter-category {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  &__header {
    margin: 0;
    font-size: 17px;
    font-family: $rubik-font;
    font-weight: bold;
  }
  &__filters {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
}
