@use '../../constants.scss' as *;
.rapid-game-card {
  &__ctas {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }
  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  &__time {
    font-style: italic;
    font-size: 12px;
  }
  &__pace {
    font-size: 16px;
  }
  &__primary-link {
    width: 40%;
    @include primary-cta;
  }
  &__secondary-link {
    width: 40%;
    @include secondary-cta;
  }
}
