@use '../../constants.scss' as *;
@use 'sass:color';

.cms-preview {
  display: flex;
  flex-direction: column;
  gap: 6px;
  box-sizing: border-box;
  padding: 20px;
  background-color: white;
  & > * {
    max-width: 300px;
  }
  &__disclaimer {
    font-size: 10px;
    font-family: $rubik-font;
    color: color.adjust(black, $lightness: 30%);
  }
}
