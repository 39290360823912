@use '../../constants.scss' as *;
.game-details-page-structure {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  margin-top: 20px;
  padding: 0 16px;
  box-sizing: border-box;
  @media screen and (min-width: $breakpoint-desktop) {
    flex-direction: row;
    align-items: flex-start;
  }
  &--no-results {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .game-details-page-structure {
      &__info {
        width: 100%;
      }
    }
  }
  &__info,
  &__results {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 8px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 5px 10px #00000026;
    @media screen and (min-width: $breakpoint-desktop) {
      margin-top: 16px;
    }
  }
  &__results {
    align-items: center;
    flex-grow: 2;
  }
}
