@use '../../constants.scss' as *;
.navigation-category {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 24px;
  box-sizing: border-box;
  background-color: white;
  @media screen and (min-width: $breakpoint-desktop) {
    width: max-content;
  }
  &__standalone-link {
    font-size: 24px;
    font-family: $rubik-font;
    font-weight: 500;
    text-decoration: none;
    color: #000000;
  }
  &__header {
    color: #e85540;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    font-family: $obviously-wide-font;
    @media screen and (min-width: $breakpoint-desktop) {
      display: none;
      visibility: hidden;
    }
  }
  &__links {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  &__link-li {
    margin: 10px 0;
    @media screen and (min-width: $breakpoint-desktop) {
      margin: 20px 0;
    }
  }
  &__link {
    padding: 10px 8px;
    padding-left: 0;
    position: relative;
    font-size: 24px;
    font-family: $rubik-font;
    font-weight: 500;
    text-decoration: none;
    color: #000000;
    @media screen and (min-width: $breakpoint-desktop) {
      font-size: 16px;
    }
  }
}
