@use '../../constants.scss' as *;
@keyframes level-up {
  to {
    z-index: 0;
  }
}

.alert-banner {
  width: 100%;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.15);
  background-color: white;
  height: 0;
  opacity: 0;
  position: relative;
  top: -500px;
  top: var(--top-start);
  z-index: -1;
  transition:
    height 0.3s ease,
    opacity 0.3s ease 0.2s,
    top 0.3s ease;

  &--is-visible {
    height: var(--alert-content-height);
    opacity: 1;
    top: 0;
    animation: level-up 0.1s linear 0.3s forwards;
  }
  &--initial-animation-complete {
    transition: none;
  }

  &__content {
    padding: 16px 48px;
    margin: 0 auto;
    position: relative;
    max-width: $max-content-width;
    box-sizing: border-box;
    text-align: center;
  }
  &__close-button {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  &__title {
    text-align: center;
    font-weight: bold;
    font-family: $font-headers;
    font-size: 16px;
    display: inline-block;
    width: 100%;
    margin-bottom: 8px;
  }
}
