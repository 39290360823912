@use '../../constants.scss' as *;
@mixin in-store-background {
  background-color: #f4f4f6;
  background-image: url('../../assets/in-store-bkg.webp');
  background-size: cover;
}

.game-lobby-more-filters {
  position: relative;
  width: 100%;
  min-height: 100%;
  max-width: $breakpoint-tablet;
  display: flex;
  flex-direction: column;
  @include in-store-background;
  color: $header-color;
  &--is-ilottery {
    color: white;
    background-color: $ilottery-blue;
    background: $ilottery-gradient;
  }
  &__header {
    padding: 16px;
    h2 {
      margin: 0;
      font-family: $font-headers;
      font-size: 22px;
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    padding: 24px 16px 100px;
    justify-content: space-between;
    flex-grow: 1;
    @media screen and (min-width: $breakpoint-tablet) {
      padding-bottom: 24px;
    }
  }
  &__filters {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
  }
  &__close-button {
    align-self: center;
    bottom: 10px;
    border: none;
    width: 80%;
    max-width: 280px;
    @include cta;
    color: white;
    background: black;
    display: block;
    cursor: pointer;
    left: 0;
    right: 0;
    &--is-ilottery {
      color: $ilottery-blue;
      background: white;
    }
  }
  &__sort {
    label {
      font-weight: bold;
    }
  }
}
