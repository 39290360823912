@use '../../constants.scss' as *;
@use 'sass:color';

.menu-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  position: relative;
  z-index: $z-index-menu-bar;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  &__content {
    width: 100%;
    max-width: $max-content-width;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }
  &__logo {
    width: 124px;
    height: 40px; // TODO remove when actual image exists
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  &__general {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }
  &__player {
    display: flex;
    align-items: center;
    column-gap: 10px;
    & > * {
      box-sizing: border-box;
    }
  }
  &__cart-container {
    position: relative;
    padding: 5px;
    border-radius: 4px;
    &:hover {
      background-color: color.adjust(white, $lightness: 15%);
    }
  }
  &__cart-notification {
    position: absolute;
    top: -10px;
    right: -10px;
  }
  &__mobile-nav-button {
    cursor: pointer;
    background-color: transparent;
    border-radius: 2px;
    display: flex;
    padding: 4px;
    &:hover {
      background-color: color.adjust(white, $lightness: 15%);
    }
    @media screen and (min-width: $breakpoint-desktop) {
      display: none;
      visibility: hidden;
    }
  }
}
