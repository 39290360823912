@use '../../constants.scss' as *;
@use 'sass:color';

.input-date {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 4px;
  &__field {
    padding: 12px 16px;
    box-sizing: border-box;
    min-height: 45px;
    background-color: white;
    text-align: left;
    border: 1px solid $input-border-color;
    border-radius: 4px;
    font-family: $font-input;
    font-size: 16px;
    &--placeholder {
      color: color.adjust($input-color, $lightness: 50%);
    }
  }
  &__modal {
    &__content {
      width: auto;
      max-width: 300px;
    }
  }
  &__calendar-icon {
    position: absolute;
    right: 16px;
    bottom: 8px;
  }
}
