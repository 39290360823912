@use '../../constants.scss' as *;
.game-tile {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: box-shadow 0.2s;
  box-shadow: 0 0 7px 2px rgba(224, 180, 2, 0.7);
  width: 100%;
  &:hover {
    box-shadow: 0 0 4px 5px rgba(224, 180, 2, 0.8);
  }
  &--is-ilottery {
    box-shadow: 0 0 2px 2px rgba(40, 181, 191, 0.7);
    &:hover {
      box-shadow: 0 0 4px 5px rgba(40, 181, 191, 0.8);
    }
  }
  &__bottom-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 6px 4px 8px;
    box-sizing: border-box;
    background-color: var(--series-bkg-color, $in-store-yellow);
    min-height: 44px;
    &--is-ilottery {
      background-color: var(--series-bkg-color, rgb(20, 54, 82));
    }
  }
  &__container {
    position: relative;
    display: flex;
    z-index: 0;
  }
  &__glow-container {
    width: 100%;
    position: absolute;
    z-index: -1;
    bottom: -10px;
    height: 40px;
    overflow: hidden;
    border-radius: 100px;
    &::before {
      content: '';
      position: absolute;
      transition: left 0.1s;
      left: 5%;
      left: calc(var(--mouse-left, 55%) - 50%);
      bottom: 10px;
      width: 90%;
      height: 25px;
      border-radius: 50%;
      // yellow bottom glow
      background-color: #e0b402;
      box-shadow: 0px 0px 8px 4px #e0b402;
    }
    &--is-ilottery {
      &::before {
        // blue bottom glow
        background-color: rgba(40, 181, 191, 1);
        box-shadow: 0px 0px 8px 4px rgba(40, 181, 191, 1);
      }
    }
  }
  &__image-container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    background-color: white;
    flex-grow: 1;
    justify-content: center;
    min-width: 160px;
    min-height: 90px;
    background-position: top center;
    background-size: contain;
    background-repeat: no-repeat;
    @media screen and (min-width: 500px) {
      min-width: 200px;
      min-height: 130px;
    }
    &--online,
    &--scratch {
      background-size: cover;
    }
    &--scratch {
      background-position: center 20%;
    }
    &--fast-play,
    &--draw {
      background-position: center;
    }
  }
  &__image-button {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  &__image-link {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  &__tagline {
    font-family: $obviously-font;
    font-size: 12px;
    font-weight: bold;
    color: var(--series-text-color, black);
    &--is-ilottery {
      color: var(--series-text-color, white);
    }
  }
}

.game-tile-option {
  min-width: 132px;
  font-size: 14px;
  &__price {
    font-size: 12px;
  }
}
