@use '../../constants.scss' as *;
@use 'sass:color';

.game-ribbon {
  $ribbon-bkg-color: var(--ribbon-bkg-color, #fff);
  position: absolute;
  z-index: 1;
  top: 38px;
  left: -17px;
  width: 100%;
  max-width: 80px;
  min-width: 35px;
  color: var(--ribbon-text-color, #2b3037);
  transform: rotate(-45deg);
  transform-origin: 0 0;
  text-align: center;
  font-family: $obviously-narrow-font;
  font-size: 14px;
  font-weight: 700;
  @media screen and (min-width: $breakpoint-tablet) {
    top: 49px;
    left: -21px;
    max-width: 100px;
    font-size: 17px;
  }

  &__text {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 0;
    left: 0;
    line-height: 1.25;
  }
}

.game-ribbon-icon {
  $ribbon-bkg-color: var(--ribbon-bkg-color, #fff);
  &__main {
    fill: $ribbon-bkg-color;
    filter: url(#outer-glow-1);
  }
  &__wrap-around {
    // unknown color at build time cannot use color.adjust
    fill: hsl(from $ribbon-bkg-color h s calc(l - 10));
  }
}
