@use '../../constants.scss' as *;
.game-calendar-header {
  background-color: var(--game-bkg-color, white);
  color: var(--game-text-color, black);
  font-family: $obviously-font;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
