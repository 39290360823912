@use '../../constants.scss' as *;
.game-collection-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  width: 100%;
  background-color: #f4f4f6;
  box-sizing: border-box;
  padding: 60px;
  @media screen and (min-width: $breakpoint-desktop) {
    flex-direction: row;
    justify-content: center;
  }
  &__image {
    width: 100%;
    max-width: 300px;
  }
  &__link {
    padding: 12px 16px;
    color: $primary-cta-color;
    background-color: $primary-cta-bkg-color;
    border-radius: 100px;
    font-weight: bold;
  }
  &__text {
    margin: 0;
    font-size: 34px;
    font-weight: 600;
    font-family: $font-headers;
    color: black;
    text-align: center;
    @media screen and (min-width: $breakpoint-desktop) {
      text-align: left;
    }
  }
  &__text-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    @media screen and (min-width: $breakpoint-desktop) {
      align-items: flex-start;
      max-width: 320px;
    }
  }
}
