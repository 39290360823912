@use '../../constants.scss' as *;
.cms-page {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-top: 20px;
  margin-bottom: 100px;
  padding: 0 8px;
  &__responsive-image,
  &__cta {
    margin: auto;
  }
  &__accordion__cta {
    align-self: flex-end;
  }
  &__image-banner {
    width: 100vw;
    margin-left: calc(-50vw + 50%);
  }
}
