@use '../../constants.scss' as *;
.filter-button {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  border-radius: 25px;
  font-family: $rubik-font;
  font-weight: bold;
  color: $regular-filter-color;
  background-color: $regular-filter-bkg-color;
  &--is-active {
    color: $regular-filter-color-selected;
    background-color: $regular-filter-bkg-color-selected;
  }
  &--is-transparent {
    border: 1px solid $transparent-filter-color;
    color: $transparent-filter-color;
    background-color: $transparent-filter-bkg-color;
    border-color: $transparent-filter-color;
    &.filter-button--is-active {
      color: $transparent-filter-color-selected;
      background-color: $transparent-filter-bkg-color-selected;
    }
  }
}
