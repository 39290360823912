@use '../../constants.scss' as *;
@use 'sass:color';

.prizes-remaining-game-card {
  display: flex;
  flex-direction: column;
  width: 100%;

  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1490196078);
  border-radius: 4px;
  background-color: white;

  font-family: $font-body;
  font-size: 16px;
  font-weight: 600;
  color: #2b3037;

  &__subtext {
    font-size: 12px;
    font-weight: 500;
  }

  &__header {
    display: flex;
    gap: 8px;
    color: #ffffff;
    border-radius: 4px;
    padding: 8px;
    box-shadow: 0px 5px 10px #00000026;
    background-color: #143652;
  }

  &__vertical {
    display: flex;
    flex-direction: column;
  }

  &__price {
    color: #143652;
    background-color: #ffffff;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 8px;

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__dropdown {
    border-top: 1px solid #dddfe1;

    &__header {
      padding: 8px;
      box-sizing: border-box;
    }
  }
}
