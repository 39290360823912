@use '../../constants.scss' as *;
.draw-game-details-page {
  &__cash-value {
    color: #676b72;
    font-size: 14px;
  }
  &__current-info {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
  }
  &__info {
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 16px;
    font-family: $font-body;
  }
  &__est-jackpot {
    font-size: 30px;
    font-weight: 600;
    line-height: 32px;
    text-align: right;
  }
  &__game-disclaimer {
    font-size: 10px;
  }
  &__game-image {
    width: fit-content;
    margin: 0 auto;
    overflow: hidden;
  }
  &__info-label {
    font-size: 14px;
    font-family: $font-body;
    font-weight: 500;
  }
  &__info-values {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
  }
  &__jackpot-info,
  &__next-drawing-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
  }
  &__next-drawing-break {
    color: #676b72;
    font-size: 14px;
  }
  &__odds-disclaimers {
    display: flex;
    gap: 4px;
    align-items: center;
    flex-direction: column;
  }
  &__overall-odds {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    font-family: $font-body;
  }
  &__overall-odds-text {
    font-weight: bold;
    font-size: 16px;
  }
  &__overall-odds-value {
    margin-left: 4px;
    color: $primary-red;
    font-weight: bold;
    font-size: 16px;
  }
  &__prize-table-container {
    gap: 8px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__prize-table-header {
    font-size: 16px;
    font-weight: 600;
    color: $primary-red;
    font-family: $font-headers;
  }
  &__prize-table-section {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  &__state {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
  }
  &__winning-numbers {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    padding-bottom: 20px;
    @media screen and (max-width: $breakpoint-desktop) {
      max-width: 420px;
    }
  }
  &__winning-numbers-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
  &__winning-numbers-title {
    font-size: 22px;
    font-weight: 600;
    font-family: $font-headers;
  }
  &__youtube-link {
    font-weight: bold;
    font-size: 16px;
    color: $primary-green;
  }
}
