@use '../../constants.scss' as *;
.map {
  width: 100%;
  height: 556px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  overflow: hidden;
  box-sizing: border-box;
  @media screen and (min-width: $breakpoint-desktop) {
    height: 810px;
  }
}
