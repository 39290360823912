@use '../../constants.scss' as *;
#skip-to-main {
  border-radius: 16px;
  background-color: $primary-cta-bkg-color;
  color: $primary-cta-color;
  border: 1px solid $cta-border-color;
  box-sizing: border-box;
  padding: 8px 12px;
  position: absolute;
  top: -200px;
  left: 16px;
  z-index: $z-index-skip-to-main;
  transition: top 0.1s ease-in-out;
  &:focus {
    top: 16px;
  }
}
