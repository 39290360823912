@use '../../constants.scss' as *;
.winners-form {
  $breakpoint-form: 800px;
  color: #2b3037;

  font-family: $rubik-font;

  &__container {
    display: flex;
    gap: 16px;
    padding: 24px 0px;
    @media (max-width: $breakpoint-form) {
      flex-direction: column;
      gap: 24px;
    }
  }

  &__filter-list-title {
    font-size: 22px;
    font-weight: 500;
    @media (max-width: $breakpoint-form) {
      font-size: 17px;
    }
  }

  &__game-multi-select {
    width: 100%;
    font-weight: normal;
    label {
      color: #2b3037;
      font-size: 14px;
    }
  }

  &__date-range-selector {
    justify-content: flex-end;
    max-width: none;
    &__text {
      padding: 12px;
    }
  }

  &__filter-button {
    @include primary-cta;
    padding: 13px 24px;
    font-size: 16px;
    border-radius: 25px;
    @media (min-width: $breakpoint-form) {
      align-self: flex-end;
    }
  }

  &__lower-container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;
  }

  &__total-results {
    color: #000;
    font-size: 16px;
    font-weight: 500;
  }

  &__remove-filters {
    display: flex;
    float: right;
    color: black;
    font-weight: 600;
    font-size: 16px;
    @media (max-width: $breakpoint-form) {
      font-size: 14px;
    }
    &:disabled {
      color: #676b72;
    }
  }
}
