@use '../../constants.scss' as *;
@use 'sass:color';

.composite-banner {
  $max-banner-height: 248px;
  $transparency-image-spacing: 20px;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: $breakpoint-desktop) {
    flex-direction: row;
    justify-content: center;
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    background-image: url('../../assets/dot-pattern.png');
    background-size: cover;
    background-position: center;
    box-sizing: border-box;
    padding: 20px 18px 30px;
    position: relative;
    @media screen and (min-width: $breakpoint-desktop) {
      align-items: flex-start;
      justify-content: center;
      height: 100%;
      padding: 20px 30px 10px;
    }
  }
  &__content-container {
    position: relative;
    width: 100%;
    background: #303e4a;
    background: radial-gradient(at right bottom, #303e4a, #232020);
    @media screen and (min-width: $breakpoint-desktop) {
      min-width: 33%;
      max-width: 33%;
      &::before {
        // triangle element
        content: '';
        position: absolute;
        top: 40%;
        left: -18px;
        width: 0;
        height: 0;
        border-top: 18px solid transparent;
        border-bottom: 18px solid transparent;
        border-right: 18px solid color.adjust(#231f20, $lightness: 2.5%);
      }
    }
  }
  &__image-banner {
    @media screen and (min-width: $breakpoint-desktop) {
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
  &__header {
    margin: 0;
    font-size: 32px;
    font-family: $font-headers;
    text-align: center;
    font-weight: 600;
    line-height: 1.1;
    @media screen and (min-width: $breakpoint-desktop) {
      text-align: left;
    }
  }
  &__subheader {
    margin-bottom: 20px;
    margin-top: 8px;
    font-size: 18px;
    font-family: $font-subheader;
    text-align: center;
    text-align: left;
  }
  &__cta {
    font-size: 14px;
    font-weight: bold;
    border-radius: 25px;
  }
}
